import { combineReducers } from "redux";
import calenderReducer from "./calendar/";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import dataList from "./data-list/";
import blog from "./blog/";
import cms from "./cms/";
import community from "./community/";
import agent from "./agent/";
import admin from "./admin/";
import customer from "./customer";
import property from "./property";
import appointment from "./appointment";

const rootReducer = combineReducers({
  calendar: calenderReducer,
  customizer: customizer,
  community: community,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  blog: blog,
  cms: cms,
  customer: customer,
  agent: agent,
  admin: admin,
  property: property,
  appointment: appointment,
});

export default rootReducer;
