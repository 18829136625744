import {ADD_COMMUNITY_POST, ALL_CMS_CATEGORIES, ALL_CMS_CATEGORY_TYPES, ALL_CMS_SUB_CATEGORIES, ALL_COMMUNITY_POSTS, COMMUNITY_POST_DETAIL, DELETE_COMMUNITY_POST, UPDATE_COMMUNITY_POST} from "../../constant/communityPage/communityPage";

const communityPostDefaultState = {
  addCommunityPostLoading: false,
  addCommunityPostData: null,
  addCommunityPostError: null,

  allCommunityPostsLoading: false,
  allCommunityPostsData: null,
  allCommunityPostsError: null,

  allPostsCategoriesLoading: false,
  allPostsCategoriesData: null,
  allPostsCategoriesError: null,

  allPostsSubCategoriesLoading: false,
  allPostsSubCategoriesData: null,
  allPostsSubCategoriesError: null,

  deleteCommunityPostLoading: false,
  deleteCommunityPostData: null,
  deleteCommunityPostError: null,

  communityPostDetailLoading: false,
  communityPostDetailData: null,
  communityPostDetailError: null,

  updateCommunityPostLoading: false,
  updateCommunityPostData: null,
  updateCommunityPostError: null,
};

export const communityReducer = (state = communityPostDefaultState, action) => {
  switch (action.type) {
    case ADD_COMMUNITY_POST.REQUEST:
      return {
        ...state,
        addCommunityPostLoading: true,
        addCommunityPostData: null,
        addCommunityPostError: null,
      };
    case ADD_COMMUNITY_POST.SUCCESS:
      return {
        ...state,
        addCommunityPostLoading: false,
        addCommunityPostData: { ...action.payload, statusCode: action.status },
        addCommunityPostError: null,
      };
    case ADD_COMMUNITY_POST.FAIL:
      return {
        ...state,
        addCommunityPostLoading: false,
        addCommunityPostData: null,
        addCommunityPostError: action.payload,
      };
    case ADD_COMMUNITY_POST.RESET:
      return {
        ...state,
        addCommunityPostLoading: false,
        addCommunityPostData: null,
        addCommunityPostError: null,
      };

    case ALL_COMMUNITY_POSTS.REQUEST:
      return {
        ...state,
        allCommunityPostsLoading: true,
        allCommunityPostsData: null,
        allCommunityPostsError: null,
      };
    case ALL_COMMUNITY_POSTS.SUCCESS:
      return {
        ...state,
        allCommunityPostsLoading: false,
        allCommunityPostsData: action.payload,
        allCommunityPostsError: null,
      };
    case ALL_COMMUNITY_POSTS.FAIL:
      return {
        ...state,
        allCommunityPostsLoading: false,
        allCommunityPostsData: null,
        allCommunityPostsError: action.payload,
      };

    case ALL_CMS_CATEGORIES.REQUEST:
        return {
          ...state,
          allCmsCategoriesLoading: true,
          allCmsCategoriesData: null,
          allCmsCategoriesError: null,
        };
    case ALL_CMS_CATEGORIES.SUCCESS:
        return {
          ...state,
          allCmsCategoriesLoading: false,
          allCmsCategoriesData: action.payload,
          allCmsCategoriesError: null,
        };
    case ALL_CMS_CATEGORIES.FAIL:
        return {
          ...state,
          allCmsCategoriesLoading: false,
          allCmsCategoriesData: null,
          allCmsCategoriesError: action.payload,
        };
  
    case ALL_CMS_SUB_CATEGORIES.REQUEST:
          return {
            ...state,
            allCmsSubCategoriesLoading: true,
            allCmsSubCategoriesData: null,
            allCmsSubCategoriesError: null,
          };
    case ALL_CMS_SUB_CATEGORIES.SUCCESS:
    return {
            ...state,
            allCmsSubCategoriesLoading: false,
            allCmsSubCategoriesData: action.payload,
            allCmsSubCategoriesError: null,
          };
    case ALL_CMS_SUB_CATEGORIES.FAIL:
          return {
            ...state,
            allCmsSubCategoriesLoading: false,
            allCmsSubCategoriesData: null,
            allCmsSubCategoriesError: action.payload,
          };
    
    case ALL_CMS_CATEGORY_TYPES.REQUEST:
            return {
              ...state,
              allCmsCategoryTypesLoading: true,
              allCmsCategoryTypesData: null,
              allCmsCategoryTypesError: null,
            };
    case ALL_CMS_CATEGORY_TYPES.SUCCESS:
      return {
              ...state,
              allCmsCategoryTypesLoading: false,
              allCmsCategoryTypesData: action.payload,
              allCmsCategoryTypesError: null,
            };
    case ALL_CMS_CATEGORY_TYPES.FAIL:
            return {
              ...state,
              allCmsCategoryTypesLoading: false,
              allCmsCategoryTypesData: null,
              allCmsCategoryTypesError: action.payload,
            };
      
    case DELETE_COMMUNITY_POST.REQUEST:
      return {
        ...state,
        deleteCommunityPostLoading: true,
        deleteCommunityPostData: null,
        deleteCommunityPostError: null,
      };
    case DELETE_COMMUNITY_POST.SUCCESS:
      return {
        ...state,
        deleteCommunityPostLoading: false,
        deleteCommunityPostData: { ...action.payload, statusCode: action.status },
        deleteCommunityPostError: null,
      };
    case DELETE_COMMUNITY_POST.FAIL:
      return {
        ...state,
        deleteCommunityPostLoading: false,
        deleteCommunityPostData: null,
        deleteCommunityPostError: action.payload,
      };
    case DELETE_COMMUNITY_POST.RESET:
      return {
        ...state,
        deleteCommunityPostLoading: false,
        deleteCommunityPostData: null,
        deleteCommunityPostError: null,
      };

    case COMMUNITY_POST_DETAIL.REQUEST:
      return {
        ...state,
        communityPostDetailLoading: true,
        communityPostDetailData: null,
        communityPostDetailError: null,
      };
    case COMMUNITY_POST_DETAIL.SUCCESS:
      return {
        ...state,
        communityPostDetailLoading: false,
        communityPostDetailData: action.payload,
        communityPostDetailError: null,
      };
    case COMMUNITY_POST_DETAIL.FAIL:
      return {
        ...state,
        communityPostDetailLoading: false,
        communityPostDetailData: null,
        communityPostDetailError: action.payload,
      };
    case COMMUNITY_POST_DETAIL.RESET:
      return {
        ...state,
        communityPostDetailLoading: false,
        communityPostDetailData: null,
        communityPostDetailError: null,
      };

    case UPDATE_COMMUNITY_POST.REQUEST:
      return {
        ...state,
        updateCommunityPostLoading: true,
        updateCommunityPostData: null,
        updateCommunityPostError: null,
      };
    case UPDATE_COMMUNITY_POST.SUCCESS:
      return {
        ...state,
        updateCommunityPostLoading: false,
        updateCommunityPostData: { ...action.payload, statusCode: action.status },
        updateCommunityPostError: null,
      };
    case UPDATE_COMMUNITY_POST.FAIL:
      return {
        ...state,
        updateCommunityPostLoading: false,
        updateCommunityPostData: null,
        updateCommunityPostError: action.payload,
      };
    case UPDATE_COMMUNITY_POST.RESET:
      return {
        ...state,
        updateCommunityPostLoading: false,
        updateCommunityPostData: null,
        updateCommunityPostError: null,
      };
    default:
      return state;
  }
};
