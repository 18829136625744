import {ADD_APPOINTMENT, ALL_APPOINTMENTS, APPOINTMENT_DETAIL, DELETE_APPOINTMENT, UPDATE_APPOINTMENT} from "../../constant/appointment/blogConstants";

export const blogDefaultState = {
  addAppointmentLoading: false,
  addAppointmentData: null,
  addAppointmentError: null,

  allAppointmentLoading: false,
  allAppointmentsData: null,
  allAppointmentsError: null,

  deleteAppointmentLoading: false,
  deleteAppointmentData: null,
  deleteAppointmentError: null,

  appointmentDetailLoading: false,
  appointmentDetailData: null,
  appointmentDetailError: null,

  updateAppointmentLoading: false,
  updateAppointmentData: null,
  updateAppointmentError: null,
};

export const appointmentReducer = (state = blogDefaultState, action) => {
  switch (action.type) {
    case ADD_APPOINTMENT.REQUEST:
      return {
        ...state,
        addAppointmentLoading: true,
        addAppointmentData: null,
        addAppointmentError: null,
      };
    case ADD_APPOINTMENT.SUCCESS:
      return {
        ...state,
        addAppointmentLoading: false,
        addAppointmentData: { ...action.payload, statusCode: action.status },
        addAppointmentError: null,
      };
    case ADD_APPOINTMENT.FAIL:
      return {
        ...state,
        addAppointmentLoading: false,
        addAppointmentData: null,
        addAppointmentError: action.payload,
      };
    case ADD_APPOINTMENT.RESET:
      return {
        ...state,
        addAppointmentLoading: false,
        addAppointmentData: null,
        addAppointmentError: null,
      };

    case ALL_APPOINTMENTS.REQUEST:
      return {
        ...state,
        allAppointmentLoading: true,
        allAppointmentsData: null,
        allAppointmentsError: null,
      };
    case ALL_APPOINTMENTS.SUCCESS:
      return {
        ...state,
        allAppointmentLoading: false,
        allAppointmentsData: action.payload,
        allAppointmentsError: null,
      };
    case ALL_APPOINTMENTS.FAIL:
      return {
        ...state,
        allAppointmentLoading: false,
        allAppointmentsData: null,
        allAppointmentsError: action.payload,
      };

    case DELETE_APPOINTMENT.REQUEST:
      return {
        ...state,
        deleteAppointmentLoading: true,
        deleteAppointmentData: null,
        deleteAppointmentError: null,
      };
    case DELETE_APPOINTMENT.SUCCESS:
      return {
        ...state,
        deleteAppointmentLoading: false,
        deleteAppointmentData: action.payload,
        deleteAppointmentError: null,
      };
    case DELETE_APPOINTMENT.FAIL:
      return {
        ...state,
        deleteAppointmentLoading: false,
        deleteAppointmentData: null,
        deleteAppointmentError: action.payload,
      };
    case DELETE_APPOINTMENT.RESET:
      return {
        ...state,
        deleteAppointmentLoading: false,
        deleteAppointmentData: null,
        deleteAppointmentError: null,
      };

    case APPOINTMENT_DETAIL.REQUEST:
      return {
        ...state,
        appointmentDetailLoading: true,
        appointmentDetailData: null,
        appointmentDetailError: null,
      };
    case APPOINTMENT_DETAIL.SUCCESS:
      return {
        ...state,
        appointmentDetailLoading: false,
        appointmentDetailData: { ...action.payload, statusCode: action.status },
        appointmentDetailError: null,
      };
    case APPOINTMENT_DETAIL.FAIL:
      return {
        ...state,
        appointmentDetailLoading: false,
        appointmentDetailData: null,
        appointmentDetailError: action.payload,
      };
    case APPOINTMENT_DETAIL.RESET:
      return {
        ...state,
        appointmentDetailLoading: false,
        appointmentDetailData: null,
        appointmentDetailError: null,
      };

    case UPDATE_APPOINTMENT.REQUEST:
      return {
        ...state,
        updateAppointmentLoading: true,
        updateAppointmentData: null,
        updateAppointmentError: null,
      };
    case UPDATE_APPOINTMENT.SUCCESS:
      return {
        ...state,
        updateAppointmentLoading: false,
        updateAppointmentData: { ...action.payload, statusCode: action.status },
        updateAppointmentError: null,
      };
    case UPDATE_APPOINTMENT.FAIL:
      return {
        ...state,
        updateAppointmentLoading: false,
        updateAppointmentData: null,
        updateAppointmentError: action.payload,
      };
    case UPDATE_APPOINTMENT.RESET:
      return {
        ...state,
        updateAppointmentLoading: false,
        updateAppointmentData: null,
        updateAppointmentError: null,
      };
    default:
      return state;
  }
};
