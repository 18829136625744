import {ADD_ADMIN, ALL_ADMINS, ADMIN_DETAIL, DELETE_ADMIN, UPDATE_ADMIN} from "../../constant/admin/adminConstants";

export const blogDefaultState = {
  addAdminLoading: false,
  addAdminData: null,
  addAdminError: null,

  allAdminsLoading: false,
  allAdminsData: null,
  allAdminsError: null,

  deleteAdminLoading: false,
  deleteAdminData: null,
  deleteAdminError: null,

  adminDetailLoading: false,
  adminDetailData: null,
  adminDetailError: null,

  updateAdminLoading: false,
  updateAdminData: null,
  updateAdminError: null,
};

export const adminReducer = (state = blogDefaultState, action) => {
  switch (action.type) {
    case ADD_ADMIN.REQUEST:
      return {
        ...state,
        addAdminLoading: true,
        addAdminData: null,
        addAdminError: null,
      };
    case ADD_ADMIN.SUCCESS:
      return {
        ...state,
        addAdminLoading: false,
        addAdminData: { ...action.payload, statusCode: action.status },
        addAdminError: null,
      };
    case ADD_ADMIN.FAIL:
      return {
        ...state,
        addAdminLoading: false,
        addAdminData: null,
        addAdminError: action.payload,
      };
    case ADD_ADMIN.RESET:
      return {
        ...state,
        addAdminLoading: false,
        addAdminData: null,
        addAdminError: null,
      };

    case ALL_ADMINS.REQUEST:
      return {
        ...state,
        allAdminsLoading: true,
        allAdminsData: null,
        allAdminsError: null,
      };
    case ALL_ADMINS.SUCCESS:
      return {
        ...state,
        allAdminsLoading: false,
        allAdminsData: action.payload,
        allAdminsError: null,
      };
    case ALL_ADMINS.FAIL:
      return {
        ...state,
        allAdminsLoading: false,
        allAdminsData: null,
        allAdminsError: action.payload,
      };

    case DELETE_ADMIN.REQUEST:
      return {
        ...state,
        deleteAdminLoading: true,
        deleteAdminData: null,
        deleteAdminError: null,
      };
    case DELETE_ADMIN.SUCCESS:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminData: { ...action.payload },
        deleteAdminError: null,
      };
    case DELETE_ADMIN.FAIL:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminData: null,
        deleteAdminError: action.payload,
      };
    case DELETE_ADMIN.RESET:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminData: null,
        deleteAdminError: null,
      };

    case ADMIN_DETAIL.REQUEST:
      return {
        ...state,
        adminDetailLoading: true,
        adminDetailData: null,
        adminDetailError: null,
      };
    case ADMIN_DETAIL.SUCCESS:
      return {
        ...state,
        adminDetailLoading: false,
        adminDetailData: action.payload,
        adminDetailError: null,
      };
    case ADMIN_DETAIL.FAIL:
      return {
        ...state,
        adminDetailLoading: false,
        adminDetailData: null,
        adminDetailError: action.payload,
      };
    case ADMIN_DETAIL.RESET:
      return {
        ...state,
        adminDetailLoading: false,
        adminDetailData: null,
        adminDetailError: null,
      };

    case UPDATE_ADMIN.REQUEST:
      return {
        ...state,
        updateAdminLoading: true,
        updateAdminData: null,
        updateAdminError: null,
      };
    case UPDATE_ADMIN.SUCCESS:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminData: action.payload,
        updateAdminError: null,
      };
    case UPDATE_ADMIN.FAIL:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminData: action.payload,
        updateAdminError: action.payload,
      };
    case UPDATE_ADMIN.RESET:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminData: null,
        updateAdminError: null,
      };
    default:
      return state;
  }
};
