export const ADD_APPOINTMENT = {
  REQUEST: "ADD_APPOINTMENT_REQUEST",
  SUCCESS: "ADD_APPOINTMENT_SUCCESS",
  FAIL: "ADD_APPOINTMENT_FAIL",
  RESET: "ADD_APPOINTMENT_RESET",
};

export const ALL_APPOINTMENTS = {
  REQUEST: "ALL_APPOINTMENTS_REQUEST",
  SUCCESS: "ALL_APPOINTMENTS_SUCCESS",
  FAIL: "ALL_APPOINTMENTS_FAIL"
};

export const DELETE_APPOINTMENT = {
  REQUEST: "DELETE_APPOINTMENT_REQUEST",
  SUCCESS: "DELETE_APPOINTMENT_SUCCESS",
  FAIL: "DELETE_APPOINTMENT_FAIL",
  RESET: "DELETE_APPOINTMENT_RESET",
};

export const APPOINTMENT_DETAIL = {
  REQUEST: "APPOINTMENT_DETAIL_REQUEST",
  SUCCESS: "APPOINTMENT_DETAIL_SUCCESS",
  FAIL: "APPOINTMENT_DETAIL_FAIL",
  RESET: "APPOINTMENT_DETAIL_RESET",
};

export const UPDATE_APPOINTMENT = {
  REQUEST: "UPDATE_APPOINTMENT_REQUEST",
  SUCCESS: "UPDATE_APPOINTMENT_SUCCESS",
  FAIL: "UPDATE_APPOINTMENT_FAIL",
  RESET: "UPDATE_APPOINTMENT_RESET",
};
