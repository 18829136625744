import {ADD_CUSTOMER, ALL_CUSTOMERS, CUSTOMER_DETAIL, DELETE_CUSTOMER, UPDATE_CUSTOMER} from "../../constant/customer/customerConstants";

export const blogDefaultState = {
  addCustomerLoading: false,
  addCustomerData: null,
  addCustomerError: null,

  allCustomersLoading: false,
  allCustomersData: null,
  allCustomersError: null,

  deleteCustomerLoading: false,
  deleteCustomerData: null,
  deleteCustomerError: null,

  customerDetailLoading: false,
  customerDetailData: null,
  customerDetailError: null,

  updateCustomerLoading: false,
  updateCustomerData: null,
  updateCustomerError: null,
};

export const customerReducer = (state = blogDefaultState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER.REQUEST:
      return {
        ...state,
        addCustomerLoading: true,
        addCustomerData: null,
        addCustomerError: null,
      };
    case ADD_CUSTOMER.SUCCESS:
      return {
        ...state,
        addCustomerLoading: false,
        addCustomerData: action.payload,
        addCustomerError: null,
      };
    case ADD_CUSTOMER.FAIL:
      return {
        ...state,
        addCustomerLoading: false,
        addCustomerData: null,
        addCustomerError: action.payload,
      };
    case ADD_CUSTOMER.RESET:
      return {
        ...state,
        addCustomerLoading: false,
        addCustomerData: null,
        addCustomerError: null,
      };

    case ALL_CUSTOMERS.REQUEST:
      return {
        ...state,
        allCustomersLoading: true,
        allCustomersData: null,
        allCustomersError: null,
      };
    case ALL_CUSTOMERS.SUCCESS:
      return {
        ...state,
        allCustomersLoading: false,
        allCustomersData: [...action.payload],
        allCustomersError: null,
      };
    case ALL_CUSTOMERS.FAIL:
      return {
        ...state,
        allCustomersLoading: false,
        allCustomersData: null,
        allCustomersError: action.payload,
      };

    case DELETE_CUSTOMER.REQUEST:
      return {
        ...state,
        deleteCustomerLoading: true,
        deleteCustomerData: null,
        deleteCustomerError: null,
      };
    case DELETE_CUSTOMER.SUCCESS:
      return {
        ...state,
        deleteCustomerLoading: false,
        deleteCustomerData: action.payload,
        deleteCustomerError: null,
      };
    case DELETE_CUSTOMER.FAIL:
      return {
        ...state,
        deleteCustomerLoading: false,
        deleteCustomerData: null,
        deleteCustomerError: action.payload,
      };
    case DELETE_CUSTOMER.RESET:
      return {
        ...state,
        deleteCustomerLoading: false,
        deleteCustomerData: null,
        deleteCustomerError: null,
      };

    case CUSTOMER_DETAIL.REQUEST:
      return {
        ...state,
        customerDetailLoading: true,
        customerDetailData: null,
        customerDetailError: null,
      };
    case CUSTOMER_DETAIL.SUCCESS:
      return {
        ...state,
        customerDetailLoading: false,
        customerDetailData: { ...action.payload, statusCode: action.status },
        customerDetailError: null,
      };
    case CUSTOMER_DETAIL.FAIL:
      return {
        ...state,
        customerDetailLoading: false,
        customerDetailData: null,
        customerDetailError: action.payload,
      };
    case CUSTOMER_DETAIL.RESET:
      return {
        ...state,
        customerDetailLoading: false,
        customerDetailData: null,
        customerDetailError: null,
      };

    case UPDATE_CUSTOMER.REQUEST:
      return {
        ...state,
        updateCustomerLoading: true,
        updateCustomerData: null,
        updateCustomerError: null,
      };
    case UPDATE_CUSTOMER.SUCCESS:
      return {
        ...state,
        updateCustomerLoading: false,
        updateCustomerData: { ...action.payload, statusCode: action.status },
        updateCustomerError: null,
      };
    case UPDATE_CUSTOMER.FAIL:
      return {
        ...state,
        updateCustomerLoading: false,
        updateCustomerData: null,
        updateCustomerError: action.payload,
      };
    case UPDATE_CUSTOMER.RESET:
      return {
        ...state,
        updateCustomerLoading: false,
        updateCustomerData: null,
        updateCustomerError: null,
      };
    default:
      return state;
  }
};
