import { combineReducers } from "redux"
import { login } from "./loginReducer"
import { register } from "./registerReducers"
import { forgotPassword } from "./forgotPasswordReducer"
import { updatePassword } from "./updatePasswordReducer"

const authReducers = combineReducers({
  login,
  register,
  forgotPassword,
  updatePassword
})

export default authReducers
