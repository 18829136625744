import {ADD_PROPERTY, ALL_PROPERTY, PROPERTY_DETAIL, DELETE_PROPERTY, UPDATE_PROPERTY} from "../../constant/property/propertyConstants";

export const blogDefaultState = {
  addPropertyLoading: false,
  addPropertyData: null,
  addPropertyError: null,

  allPropertyLoading: false,
  allPropertyData: null,
  allPropertyError: null,

  deletePropertyLoading: false,
  deletePropertyData: null,
  deletePropertyError: null,

  propertyDetailLoading: false,
  propertyDetailData: null,
  propertyDetailError: null,

  updatePropertyLoading: false,
  updatePropertyData: null,
  updatePropertyError: null,
};

export const propertyReducer = (state = blogDefaultState, action) => {
  switch (action.type) {
    case ADD_PROPERTY.REQUEST:
      return {
        ...state,
        addPropertyLoading: true,
        addPropertyData: null,
        addPropertyError: null,
      };
    case ADD_PROPERTY.SUCCESS:
      return {
        ...state,
        addPropertyLoading: false,
        addPropertyData: { ...action.payload, statusCode: action.status },
        addPropertyError: null,
      };
    case ADD_PROPERTY.FAIL:
      return {
        ...state,
        addPropertyLoading: false,
        addPropertyData: null,
        addPropertyError: action.payload,
      };
    case ADD_PROPERTY.RESET:
      return {
        ...state,
        addPropertyLoading: false,
        addPropertyData: null,
        addPropertyError: null,
      };

    case ALL_PROPERTY.REQUEST:
      return {
        ...state,
        allPropertyLoading: true,
        allPropertyData: null,
        allPropertyError: null,
      };
    case ALL_PROPERTY.SUCCESS:
      return {
        ...state,
        allPropertyLoading: false,
        allPropertyData: action.payload,
        allPropertyError: null,
      };
    case ALL_PROPERTY.FAIL:
      return {
        ...state,
        allPropertyLoading: false,
        allPropertyData: null,
        allPropertyError: action.payload,
      };

    case DELETE_PROPERTY.REQUEST:
      return {
        ...state,
        deletePropertyLoading: true,
        deletePropertyData: null,
        deletePropertyError: null,
      };
    case DELETE_PROPERTY.SUCCESS:
      return {
        ...state,
        deletePropertyLoading: false,
        deletePropertyData: { ...action.payload, statusCode: action.status },
        deletePropertyError: null,
      };
    case DELETE_PROPERTY.FAIL:
      return {
        ...state,
        deletePropertyLoading: false,
        deletePropertyData: null,
        deletePropertyError: action.payload,
      };
    case DELETE_PROPERTY.RESET:
      return {
        ...state,
        deletePropertyLoading: false,
        deletePropertyData: null,
        deletePropertyError: null,
      };

    case PROPERTY_DETAIL.REQUEST:
      return {
        ...state,
        propertyDetailLoading: true,
        PropertyDetailData: null,
        propertyDetailError: null,
      };
    case PROPERTY_DETAIL.SUCCESS:
      return {
        ...state,
        propertyDetailLoading: false,
        PropertyDetailData: action.payload,
        propertyDetailError: null,
      };
    case PROPERTY_DETAIL.FAIL:
      return {
        ...state,
        propertyDetailLoading: false,
        PropertyDetailData: null,
        propertyDetailError: action.payload,
      };
    case PROPERTY_DETAIL.RESET:
      return {
        ...state,
        propertyDetailLoading: false,
        PropertyDetailData: null,
        propertyDetailError: null,
      };

    case UPDATE_PROPERTY.REQUEST:
      return {
        ...state,
        updatePropertyLoading: true,
        updatePropertyData: null,
        updatePropertyError: null,
      };
    case UPDATE_PROPERTY.SUCCESS:
      return {
        ...state,
        updatePropertyLoading: false,
        updatePropertyData: action.payload,
        updatePropertyError: null,
      };
    case UPDATE_PROPERTY.FAIL:
      return {
        ...state,
        updatePropertyLoading: false,
        updatePropertyData: null,
        updatePropertyError: action.payload,
      };
    case UPDATE_PROPERTY.RESET:
      return {
        ...state,
        updatePropertyLoading: false,
        updatePropertyData: null,
        updatePropertyError: null,
      };
    default:
      return state;
  }
};
