import {FORGOT_PASSWORD} from "../../constant/auth/forgotPasswordConstants";

const forgotPasswordDefaultState = {
  userRole: "admin",
  forgotPasswordLoading: false,
  forgotPasswordData: null,
  forgotPasswordError: null
}

export const forgotPassword = (state = forgotPasswordDefaultState, action) => {
  switch (action.type) {
    case "FORGOT_PASSWORD": {
      return { ...state, values: action.payload }
    }
    case FORGOT_PASSWORD.REQUEST:
      return {...state, forgotPasswordLoading: true, forgotPasswordData: null, forgotPasswordError: null}
    case FORGOT_PASSWORD.SUCCESS:
      return {...state, forgotPasswordLoading: false, forgotPasswordData: {...action.payload, statusCode: action.status}, forgotPasswordError: null}
    case FORGOT_PASSWORD.FAIL:
      return {...state, forgotPasswordLoading: false, forgotPasswordData: null, forgotPasswordError: action.payload}
    default: {
      return state
    }
  }
}
