import { UPDATE_PASSWORD } from "../../constant/auth/updatePasswordConstants";

const updatePasswordDefaultState = {
  userRole: "admin",
  updatePasswordLoading: false,
  updatePasswordData: null,
  updatePasswordError: null,
};

export const updatePassword = (state = updatePasswordDefaultState, action) => {
  switch (action.type) {
    case "UPDATE_PASSWORD": {
      return { ...state, values: action.payload };
    }
    case UPDATE_PASSWORD.REQUEST:
      return {
        ...state,
        updatePasswordLoading: true,
        updatePasswordData: null,
        updatePasswordError: null,
      };
    case UPDATE_PASSWORD.SUCCESS:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordData: {...action.payload, statusCode: action.status},
        updatePasswordError: null,
      };
    case UPDATE_PASSWORD.FAIL:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordData: null,
        updatePasswordError: action.payload,
      };
    default: {
      return state;
    }
  }
};
