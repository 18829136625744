export const ADD_CUSTOMER = {
  REQUEST: "ADD_CUSTOMER_REQUEST",
  SUCCESS: "ADD_CUSTOMER_SUCCESS",
  FAIL: "ADD_CUSTOMER_FAIL",
  RESET: "ADD_CUSTOMER_RESET",
};

export const ALL_CUSTOMERS = {
  REQUEST: "ALL_CUSTOMERS_REQUEST",
  SUCCESS: "ALL_CUSTOMERS_SUCCESS",
  FAIL: "ALL_CUSTOMERS_FAIL"
};

export const DELETE_CUSTOMER = {
  REQUEST: "DELETE_CUSTOMER_REQUEST",
  SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  FAIL: "DELETE_CUSTOMER_FAIL",
  RESET: "DELETE_CUSTOMER_RESET",
};

export const CUSTOMER_DETAIL = {
  REQUEST: "CUSTOMER_DETAIL_REQUEST",
  SUCCESS: "CUSTOMER_DETAIL_SUCCESS",
  FAIL: "CUSTOMER_DETAIL_FAIL",
  RESET: "CUSTOMER_DETAIL_RESET",
};

export const UPDATE_CUSTOMER = {
  REQUEST: "UPDATE_CUSTOMER_REQUEST",
  SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  FAIL: "UPDATE_CUSTOMER_FAIL",
  RESET: "UPDATE_CUSTOMER_RESET",
};
