import {ADD_AGENT, ALL_AGENTS, AGENT_DETAIL, DELETE_AGENT, UPDATE_AGENT} from "../../constant/agent/agentConstants";

export const blogDefaultState = {
  addAgentLoading: false,
  addAgentData: null,
  addAgentError: null,

  allAgentsLoading: false,
  allAgentsData: null,
  allAgentsError: null,

  deleteAgentLoading: false,
  deleteAgentData: null,
  deleteAgentError: null,

  agentDetailLoading: false,
  agentDetailData: null,
  agentDetailError: null,

  updateAgentLoading: false,
  updateAgentData: null,
  updateAgentError: null,
};

export const agentReducer = (state = blogDefaultState, action) => {
  switch (action.type) {
    case ADD_AGENT.REQUEST:
      return {
        ...state,
        addAgentLoading: true,
        addAgentData: null,
        addAgentError: null,
      };
    case ADD_AGENT.SUCCESS:
      return {
        ...state,
        addAgentLoading: false,
        addAgentData: action.payload,
        addAgentError: null,
      };
    case ADD_AGENT.FAIL:
      return {
        ...state,
        addAgentLoading: false,
        addAgentData: null,
        addAgentError: action.payload,
      };
    case ADD_AGENT.RESET:
      return {
        ...state,
        addAgentLoading: false,
        addAgentData: null,
        addAgentError: null,
      };

    case ALL_AGENTS.REQUEST:
      return {
        ...state,
        allAgentsLoading: true,
        allAgentsData: null,
        allAgentsError: null,
      };
    case ALL_AGENTS.SUCCESS:

      return {
        ...state,
        allAgentsLoading: false,
        allAgentsData: action.payload,
        allAgentsError: null,
      };
    case ALL_AGENTS.FAIL:
      
      return {
        ...state,
        allAgentsLoading: false,
        allAgentsData: null,
        allAgentsError: action.payload,
      };

    case DELETE_AGENT.REQUEST:
      return {
        ...state,
        deleteAgentLoading: true,
        deleteAgentData: null,
        deleteAgentError: null,
      };
    case DELETE_AGENT.SUCCESS:
      return {
        ...state,
        deleteAgentLoading: false,
        deleteAgentData: { ...action.payload },
        deleteAgentError: null,
      };
    case DELETE_AGENT.FAIL:
      return {
        ...state,
        deleteAgentLoading: false,
        deleteAgentData: null,
        deleteAgentError: action.payload,
      };
    case DELETE_AGENT.RESET:
      return {
        ...state,
        deleteAgentLoading: false,
        deleteAgentData: null,
        deleteAgentError: null,
      };

    case AGENT_DETAIL.REQUEST:
      return {
        ...state,
        agentDetailLoading: true,
        agentDetailData: null,
        agentDetailError: null,
      };
    case AGENT_DETAIL.SUCCESS:
      return {
        ...state,
        agentDetailLoading: false,
        agentDetailData: { ...action.payload, statusCode: action.status },
        agentDetailError: null,
      };
    case AGENT_DETAIL.FAIL:
      return {
        ...state,
        agentDetailLoading: false,
        agentDetailData: null,
        agentDetailError: action.payload,
      };
    case AGENT_DETAIL.RESET:
      return {
        ...state,
        agentDetailLoading: false,
        agentDetailData: null,
        agentDetailError: null,
      };

    case UPDATE_AGENT.REQUEST:
      return {
        ...state,
        updateAgentLoading: true,
        updateAgentData: null,
        updateAgentError: null,
      };
    case UPDATE_AGENT.SUCCESS:
      return {
        ...state,
        updateAgentLoading: false,
        updateAgentData: { ...action.payload, statusCode: action.status },
        updateAgentError: null,
      };
    case UPDATE_AGENT.FAIL:
      return {
        ...state,
        updateAgentLoading: false,
        updateAgentData: action.payload,
        updateAgentError: action.payload,
      };
    case UPDATE_AGENT.RESET:
      return {
        ...state,
        updateAgentLoading: false,
        updateAgentData: null,
        updateAgentError: null,
      };
    default:
      return state;
  }
};
