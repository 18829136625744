export const ADD_COMMUNITY_POST = {
  REQUEST: "ADD_COMMUNITY_POST_REQUEST",
  SUCCESS: "ADD_COMMUNITY_POST_SUCCESS",
  FAIL: "ADD_COMMUNITY_POST_FAIL",
  RESET: "ADD_COMMUNITY_POST_RESET",
};

export const ALL_COMMUNITY_POSTS = {
  REQUEST: "ALL_COMMUNITY_POSTS_REQUEST",
  SUCCESS: "ALL_COMMUNITY_POSTS_SUCCESS",
  FAIL: "ALL_COMMUNITY_POSTS_FAIL",
};

export const ALL_CMS_CATEGORIES = {
  REQUEST: "ALL_CMS_CATEGORIES_REQUEST",
  SUCCESS: "ALL_CMS_CATEGORIES_SUCCESS",
  FAIL: "ALL_CMS_CATEGORIES_FAIL",
};

export const ALL_CMS_SUB_CATEGORIES = {
  REQUEST: "ALL_CMS_SUB_CATEGORIES_REQUEST",
  SUCCESS: "ALL_CMS_SUB_CATEGORIES_SUCCESS",
  FAIL: "ALL_CMS_SUB_CATEGORIES_FAIL",
};

export const ALL_CMS_CATEGORY_TYPES = {
  REQUEST: "ALL_CMS_CATEGORY_TYPES_REQUEST",
  SUCCESS: "ALL_CMS_CATEGORY_TYPES_SUCCESS",
  FAIL: "ALL_CMS_CATEGORY_TYPES_FAIL",
};

export const DELETE_COMMUNITY_POST = {
  REQUEST: "DELETE_COMMUNITY_POST_REQUEST",
  SUCCESS: "DELETE_COMMUNITY_POST_SUCCESS",
  FAIL: "DELETE_COMMUNITY_POST_FAIL",
  RESET: "DELETE_COMMUNITY_POST_RESET",
};

export const COMMUNITY_POST_DETAIL = {
  REQUEST: "COMMUNITY_POST_DETAIL_REQUEST",
  SUCCESS: "COMMUNITY_POST_DETAIL_SUCCESS",
  FAIL: "COMMUNITY_POST_DETAIL_FAIL",
  RESET: "COMMUNITY_POST_DETAIL_RESET",
};

export const UPDATE_COMMUNITY_POST = {
  REQUEST: "UPDATE_COMMUNITY_POST_REQUEST",
  SUCCESS: "UPDATE_COMMUNITY_POST_SUCCESS",
  FAIL: "UPDATE_COMMUNITY_POST_FAIL",
  RESET: "UPDATE_COMMUNITY_POST_RESET",
};
