export const ADD_PROPERTY = {
  REQUEST: "ADD_PROPERTY_REQUEST",
  SUCCESS: "ADD_PROPERTY_SUCCESS",
  FAIL: "ADD_PROPERTY_FAIL",
  RESET: "ADD_PROPERTY_RESET",
};

export const ALL_PROPERTY = {
  REQUEST: "ALL_PROPERTY_REQUEST",
  SUCCESS: "ALL_PROPERTY_SUCCESS",
  FAIL: "ALL_PROPERTY_FAIL"
};

export const DELETE_PROPERTY = {
  REQUEST: "DELETE_PROPERTY_REQUEST",
  SUCCESS: "DELETE_PROPERTY_SUCCESS",
  FAIL: "DELETE_PROPERTY_FAIL",
  RESET: "DELETE_PROPERTY_RESET",
};

export const PROPERTY_DETAIL = {
  REQUEST: "PROPERTY_DETAIL_REQUEST",
  SUCCESS: "PROPERTY_DETAIL_SUCCESS",
  FAIL: "PROPERTY_DETAIL_FAIL",
  RESET: "PROPERTY_DETAIL_RESET",
};

export const UPDATE_PROPERTY = {
  REQUEST: "UPDATE_PROPERTY_REQUEST",
  SUCCESS: "UPDATE_PROPERTY_SUCCESS",
  FAIL: "UPDATE_PROPERTY_FAIL",
  RESET: "UPDATE_PROPERTY_RESET",
};
