export const ADD_AGENT = {
  REQUEST: "ADD_AGENT_REQUEST",
  SUCCESS: "ADD_AGENT_SUCCESS",
  FAIL: "ADD_AGENT_FAIL",
  RESET: "ADD_AGENT_RESET",
};

export const ALL_AGENTS = {
  REQUEST: "ALL_AGENTS_REQUEST",
  SUCCESS: "ALL_AGENTS_SUCCESS",
  FAIL: "ALL_AGENTS_FAIL"
};

export const ALL_AGENT_USERS = {
  REQUEST: "ALL_AGENT_USERS_REQUEST",
  SUCCESS: "ALL_AGENT_USERS_SUCCESS",
  FAIL: "ALL_AGENT_USERS_FAIL"
};

export const DELETE_AGENT = {
  REQUEST: "DELETE_AGENT_REQUEST",
  SUCCESS: "DELETE_AGENT_SUCCESS",
  FAIL: "DELETE_AGENT_FAIL",
  RESET: "DELETE_AGENT_RESET",
};

export const AGENT_DETAIL = {
  REQUEST: "AGENT_DETAIL_REQUEST",
  SUCCESS: "AGENT_DETAIL_SUCCESS",
  FAIL: "AGENT_DETAIL_FAIL",
  RESET: "AGENT_DETAIL_RESET",
};

export const UPDATE_AGENT = {
  REQUEST: "UPDATE_AGENT_REQUEST",
  SUCCESS: "UPDATE_AGENT_SUCCESS",
  FAIL: "UPDATE_AGENT_FAIL",
  RESET: "UPDATE_AGENT_RESET",
};

export const JOB_TITLE = [
  { value: "developer", label: "Developer" },
  { value: "real_estate_agent", label: "Real Estate Agent" },
  { value: "freelance_agent", label: "Freelance Agent" },
  { value: "landlord", label: "Landlord" },
];